.game-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px;
}

input {
    padding: 10px;
    font-size: 16px;
    width: 150px;
}

button {
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
}

.word-line {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.animate-flash {
    animation: flash 2s ease-in-out;
  }

.animate-confetti {
    animation: confetti 3s ease-in-out;
  }
  